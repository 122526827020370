@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  padding: fn.spacing(6) 0;
  overflow: hidden;
}

.divider {
  border-top: 1px solid #c0cbd8;
  margin: 32px 0;
}

.subtitle {
  flex-direction: column;
  max-width: fn.spacing(101);
  margin: auto;
  padding-top: fn.spacing(6);
}

.disclaimer {
  max-width: fn.spacing(101);
  margin: auto;
  padding-top: fn.spacing(6);
  text-align: center;
  color: #42546b;
}

@media screen and (min-width: 1024px) {
  .section {
    padding: 64px 0;
  }
}
